// --- MODAL --- //
document.body.addEventListener('click', toggleModal);

function toggleModal(el) {
  // Define the element that has triggered the
  var modalTrigger = el.target;

  // Check to see if element CAN open the modal
  if(modalTrigger.classList.contains('modalToggle')) {
    // Get the ID from the element
    var modalId = modalTrigger.getAttribute('href').substr(1);
    // Modal content container
    var modal = document.getElementById(modalId);
    // Check state if it's a close click
    if(modalTrigger.classList.contains('modal__closer')) {
      // Clear all modals
      clearModals();
    } else {
      // Clear all exisiting modals before opening new one
      clearModals();
      // Open targeted modal
      openModal(modal);
    }

    el.preventDefault();

  }

}

// Clear all open modals
function clearModals() {
  document.body.classList.remove('modalOpen');
  var openModals = document.querySelectorAll('.modal');
  // Fix for older browsers - can't handle a non-Native array :(
  var openModals = Array.prototype.slice.call(openModals);
  openModals.forEach(function(modal){
    modal.classList.remove('on');
  });
}

// Open targeted modal
function openModal(id) {
  id.classList.add('on');
  document.body.classList.add('modalOpen');
}
// --- MODAL END --- //

// --- TOASTER --- //

// Create our message timer
var messagetimer;

function messageToaster(title, message, type) {

  clearTimeout(messagetimer);

  const toaster = document.querySelector('.toaster');

  clearToaster(toaster);

  if(type === 'failed'){
    toaster.classList.add('failed');
  } else {
    toaster.classList.add('success');
  }

  document.querySelector('.toaster__title').innerText = title;
  document.querySelector('.toaster__content').innerText = message;
  toaster.classList.add('open');

  messagetimer = setTimeout(function() {
      toaster.classList.remove('open');
      window.history.replaceState(null, null, window.location.pathname);
  }, 8000);

}

function clearToaster(toaster) {
  toaster.classList.remove('failed', 'success');
}

// First at the URL to target correct message
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
// Look for a message parameter
var message = getParameterByName("message");

if(message === 'failed'){
  messageToaster('Sorry.', 'There has been a problem, please try again...', 'failed');
}
if(message === 'success'){
  messageToaster('Message sent.', 'David will be in touch soon.', 'success');
}

// --- TOASTER END --- //

// --- CONTACT FORM --- //
const contactForm = document.getElementById('contactForm');

// Form processing with recaptcha and messaging
contactForm.addEventListener('submit', submitForm);

function submitForm(e) {
  e.preventDefault();
  document.querySelector('.submitButton').setAttribute('disabled', 'disabled');
  grecaptcha.reset();
  grecaptcha.execute();
}
function formSubmit(response) {
  contactForm.submit();
}
// --- CONTACT FORM END --- //
